exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contactus-tsx": () => import("./../../../src/pages/contactus.tsx" /* webpackChunkName: "component---src-pages-contactus-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-payment-complete-[id]-tsx": () => import("./../../../src/pages/payment-complete/[id].tsx" /* webpackChunkName: "component---src-pages-payment-complete-[id]-tsx" */),
  "component---src-pages-ts-cs-tsx": () => import("./../../../src/pages/ts&cs.tsx" /* webpackChunkName: "component---src-pages-ts-cs-tsx" */),
  "component---src-templates-item-tsx": () => import("./../../../src/templates/item.tsx" /* webpackChunkName: "component---src-templates-item-tsx" */)
}

